import { put, call, all, takeLatest } from 'redux-saga/effects';
// import { message } from 'antd';
import message from "antd/lib/message";
import intl from 'react-intl-universal';
import { Toast } from '@/utils';
import Api from '@/api/index';
import { regenerateMenuData } from '@/common/menu';
import { getFirstMenu } from '@/utils/menu';
import { LOGIN_REQUEST, LOGOUT_REQUEST, UPDATE_MENU_REQUEST } from '@/constants/ActionsType';
import {
  loginSuccess,
  loginFailure,
  logoutSuccess,
  updateToken,
  updateMenuSuccess,
} from '../actions/user';

function loginFetch(username, password, realKey, code, vendorCode) {
  return Api.Login({
    username,
    password,
    realKey,
    code,
    vendorCode,
  });
}

function logoutFetch() {
  return Api.Logout();
}

function menuRequest() {
  return Api.menusBuild();
}

function* loginRequest(action) {
  const {
    payload: { userName, password, realKey, code, vendorCode },
    callback,
  } = action;
  try {
    const response = yield call(loginFetch, userName, password, realKey, code, vendorCode);

    if (response.code === 10000) {
      message.success(intl.get('LoginSuccess'));
      // 更新token
      yield put(updateToken(response.data.token));
      // 获取菜单数据
      const menuRes = yield call(menuRequest);
      const menu = regenerateMenuData(menuRes.data.list);
      // 获取默认打开的第一个链接
      const jumpUrl = getFirstMenu('/', menu);
      const {
        token,
        user: { type, roles, avatar },
      } = response.data;
      yield put(
        loginSuccess({
          token,
          userName,
          menuPermissions: menu,
          type,
          jumpUrl,
          roles,
          avatar,
        }),
      );
    } else if (response.code === 10011) {
      yield put(loginFailure(404, intl.get('LoginVerifyCodeExpired')));
      if (callback) {
        callback();
      }
    } else if (response.code === 10012) {
      yield put(loginFailure(404, intl.get('LoginVerifyCodeError')));
    } else if (response.code === 10013) {
      yield put(loginFailure(404, intl.get('LoginResultError')));
      if (callback) {
        callback();
      }
    } else if (response.code === 10014) {
      yield put(loginFailure(404, intl.get('LoginDisable')));
      if (callback) {
        callback();
      }
    } else {
      yield put(loginFailure(response.code, response.message));
      if (callback) {
        callback();
      }
    }
  } catch (e) {
    yield put(loginFailure(404, intl.get('LoginError')));
    if (callback) {
      callback();
    }
  }
}

function* logoutRequest() {
  try {
    const res = yield call(logoutFetch);
    if (res.code === 10000) {
      yield put(logoutSuccess());
    }
  } catch (e) {
    Toast(intl.get('LogoutError'));
  }
}

function* updateMenuRequest() {
  try {
    const res = yield call(menuRequest);
    if (res.code === 10000) {
      const menu = regenerateMenuData(res.data.list);
      yield put(updateMenuSuccess(menu));
    }
  } catch (e) {
    Toast(intl.get('UpdateMenuError'));
  }
}

export default function* root() {
  yield all([
    takeLatest(LOGIN_REQUEST, loginRequest),
    takeLatest(LOGOUT_REQUEST, logoutRequest),
    takeLatest(UPDATE_MENU_REQUEST, updateMenuRequest),
  ]);
}
